












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CircleChart extends Vue {

  @Prop()
  private progress!: number

  @Prop()
  private totalProgress!: number

  private get statusClass(): string {
    if (this.progress > 0 && this.progress >= this.totalProgress) {
      return 'finished'
    } else if (this.progress > 0) {
      return 'busy'
    }
    return 'notstarted'
  }

  private get percentage(): string {
    return `${this.progress / this.totalProgress * 100}`
  }

}
