












import { RawLocation } from 'vue-router'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EntityCollectionResource } from '@/models/implementations/entities/EntityCollectionResource'

import ScoreCard from '@/components/misc/Scorecard.vue'

@Component({
  components: {
    ScoreCard
  }
})
export default class Entity extends Vue {

  @Prop()
  private entity!: EntityCollectionResource

  private get route(): RawLocation {
    return {
      name: 'implementation-entity-implementations',
      params: { ...this.$route.params, entity_id: `${this.entity.id}` },
    }
  }

}
